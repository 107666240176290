<template>
    <div v-if="show_div_type" id="aside" class="app-aside fade box-shadow-x nav-expand white" aria-hidden="true">
        <div class="sidenav modal-dialog dk white dark">
          <!-- sidenav top -->
            <div class="navbar lt">
              <!-- brand -->
              <a href="/" class="navbar-brand">
                    <span class="avatar w-100">
                        <img src="@/assets/images/logo-with-icon.png" alt="logo">
                    </span>
              </a>
              <!-- / brand -->
            </div>
            <!-- Flex nav content -->
            <div class="flex">
              <div class="scroll">
                  <div class="nav-border b-primary" data-nav>
                      <ul class="nav bg">
                          <li>
                              <router-link :to="{ name: 'dashboard' }">
                                  <span class="nav-icon"><i class="fa fa-dashboard"></i></span>
                                  <span class="nav-text"><router-link :to="{ name: 'dashboard' }">Dashboard</router-link></span>
                              </router-link>
                          </li>
                          <li v-if="isSuperAdmin">
                              <router-link :to="{ name: 'module-and-menu-setup' }">
                                  <span class="nav-icon"><i class="fa fa-shield"></i></span>
                                  <span class="nav-text">
                                    <router-link :to="{ name: 'module-and-menu-setup' }">Module and Menu Setup</router-link>
                                  </span>
                              </router-link>
                          </li>
                          <li v-if="userPermissionLabel('Monitoring', 'List')">
                              <router-link :to="{ name: 'web-pages-applications' }">
                                  <span class="nav-icon"><i class="fa fa-desktop"></i></span>
                                  <span class="nav-text"><router-link :to="{ name: 'web-pages-applications' }">Monitoring</router-link></span>
                              </router-link>
                          </li>
                          <li>
                              <router-link :to="{ name: 'rethink-dashboard-monitoring' }">
                                  <span class="nav-icon"><i class="fa fa-line-chart"></i></span>
                                  <span class="nav-text"><router-link :to="{ name: 'rethink-dashboard-monitoring' }">Rethink Monitoring</router-link></span>
                              </router-link>
                          </li>
                          <li v-if="userPermissionLabel('Activity Log', 'List')">
                              <router-link :to="{ name: 'activity-history' }">
                                  <span class="nav-icon"><i class="fa fa-history"></i></span>
                                  <span class="nav-text"><router-link :to="{ name: 'activity-history' }">Activity Log</router-link></span>
                              </router-link>
                          </li>
                          <!--Depricated-->
                          <!-- <li v-if="userPermissionLabel('Import File Log')">
                              <router-link :to="{ name: 'import-file-log' }">
                                  <span class="nav-icon"><i class="fa fa-assistive-listening-systems"></i></span>
                                  <span class="nav-text"><router-link :to="{ name: 'import-file-log' }">Import File Log</router-link></span>
                              </router-link>
                          </li> -->
                          <li v-if="userPermissionLabel('Work Flows', 'List')">
                              <router-link :to="{ name: 'work-flows' }">
                                  <span class="nav-icon"><i class="fa fa-th-list"></i></span>
                                  <span class="nav-text"><router-link :to="{ name: 'work-flows' }">Work Flows</router-link></span>
                              </router-link>
                          </li>
                          <li v-if="userPermissionLabel('Organization List', 'List')">
                              <router-link :to="{ name: 'organizations' }">
                                  <span class="nav-icon"><i class="fa fa-building-o"></i></span>
                                  <span class="nav-text"><router-link :to="{ name: 'organizations' }">Organization List</router-link></span>
                              </router-link>
                          </li>
                          <li v-if="userPermissionSubLabel('List', 'List')">
                            <router-link :to="{ name: 'student' }">
                                <span class="nav-icon"><i class="fa fa-child"></i></span>
                                <span class="nav-text">Student List</span>
                            </router-link>
                          </li>
                          <li v-if="userPermissionLabel('Staff')">
                              <a>
                                  <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                  <span class="nav-icon"><i class="fa fa-users"></i></span>
                                  <span class="nav-text">Staff</span>
                              </a>
                              <ul class="nav-sub">
                                <li v-if="userPermissionSubLabel('Staff List', 'List')">
                                    <router-link :to="{ name: 'users' }">
                                        <span class="nav-text">Staff List</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Staff Salary', 'List')">
                                    <router-link :to="{ name: 'staff-salary' }">
                                        <span class="nav-text">Staff Salary</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Upload File Settings')">
                                    <router-link :to="{ name: 'document-settings' }">
                                        <span class="nav-text">Upload File Settings</span>
                                    </router-link>
                                </li>
                                <!--<li>
                                    <router-link :to="{ name: 'due-payout' }">
                                        <span class="nav-text">Due Payout</span>
                                    </router-link>
                                </li> -->
                            </ul>
                          </li>


                          <li v-if="userPermissionLabel('Calendar')">
                              <a>
                                  <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                  <span class="nav-icon"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                                  <span class="nav-text">Calendar</span>
                              </a>
                              <ul class="nav-sub">
                                <li v-if="userPermissionSubLabel('Calendar / List View', 'List')">
                                    <router-link :to="{ name: 'appointment-list' }">
                                        <!-- <i class="fa fa-inbox d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">List View</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Calendar / List View', 'List')">
                                    <router-link :to="{ name: 'global-calendar' }">
                                        <!-- <i class="fa fa-plus d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">Calendar View</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Rethink Session Data', 'List')">
                                    <router-link :to="{ name: 'rethink-sessions-data' }">
                                        <span class="nav-text d-inline">Rethink Session Data</span>
                                    </router-link>
                                </li>
                                <!-- <i class="fa fa-plus d-inline p-2" aria-hidden="true"></i> -->
                                <!-- <li>
                                    <router-link :to="{ name: 'group-schedule' }">
                                        
                                        <span class="nav-text d-inline">Group Schedule</span>
                                    </router-link>
                                </li> -->
                                <!-- <i class="fa fa-plus d-inline p-2" aria-hidden="true"></i> -->
                                <!-- <li>
                                    <router-link :to="{ name: 'billing-schedule' }">
                                        
                                        <span class="nav-text d-inline">Billing Schedule</span>
                                    </router-link>
                                </li> -->
                              </ul>
                          </li>
                          <li v-if="userPermissionLabel('Goal Generator')">
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-bullseye" aria-hidden="true"></i></span>
                                <span class="nav-text">Goal Generator</span>
                            </a>
                            <ul class="nav-sub">
                                <li v-if="userPermissionSubLabel('Excel To Document')">
                                    <router-link :to="{ name: 'excel-document' }">
                                        <!-- <i class="fa fa-plus d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">Excel to Document</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Document To Excel')">
                                    <router-link :to="{ name: 'document-excel' }">
                                        <!-- <i class="fa fa-inbox d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">Document to Excel</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('TP Conversion Template', 'List')">
                                    <router-link :to="{ name: 'document-conversion-template-list' }">
                                        <!-- <i class="fa fa-inbox d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">TP Conversion Template</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Goal Activity', 'List')">
                                    <router-link :to="{ name: 'goal-activity-list' }">
                                        <!-- <i class="fa fa-inbox d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">Goal Activity</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Template Short Code', 'List')">
                                    <router-link :to="{ name: 'template-short-code' }">
                                        <!-- <i class="fa fa-inbox d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">Template Short Code</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('TP Conversion V-II')">
                                    <router-link :to="{ name: 'conversion-template-list' }">
                                        <span class="nav-text d-inline">TP Conversion V-II</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Document to Excel V-II')">
                                    <router-link :to="{ name: 'document-excel-v2' }">
                                        <!-- <i class="fa fa-inbox d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">Document to Excel V-II</span>
                                    </router-link>
                                </li>
                            </ul>
                            
                          </li>
                          <li  v-if="userPermissionLabel('Invoice')">
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-clipboard"></i></span>
                                <span class="nav-text">Invoice</span>
                            </a>
                            <ul class="nav-sub">
                                <li v-if="userPermissionSubLabel('Invoice List / Import / Export ')">
                                    <router-link :to="{ name: 'invoice-list' }">
                                        <span class="nav-text">Invoice List / Import / Export</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Request SETSS Invoice')">
                                    <router-link :to="{ name: 'invoice-setss-request' }">
                                        <span class="nav-text">Request SETSS Invoice</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Request SETSS Invoice')">
                                    <router-link :to="{ name: 'invoice-report' }">
                                        <span class="nav-text">Invoice Report</span>
                                    </router-link>
                                </li>
                            </ul>
                          </li>
                          <li  v-if="userPermissionLabel('Error List', 'List')">
                            <router-link :to="{ name: 'error-list' }">
                                <span class="nav-icon"><i class="fa fa-exclamation-triangle"></i></span>
                                <span class="nav-text">Error List</span>
                            </router-link>
                          </li>
                          <li v-if="userPermissionLabel('Templates')">
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-table" aria-hidden="true"></i></span>
                                <span class="nav-text">Templates</span>
                            </a>
                            <ul class="nav-sub">
                                <li v-if="userPermissionSubLabel('Template List')">
                                    <router-link :to="{ name: 'templates' }">
                                        <span class="nav-text d-inline">Template List</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Merge Field', 'List')">
                                    <router-link :to="{ name: 'merge-field' }">
                                        <span class="nav-text d-inline">Merge Field</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Case Documents', 'List')">
                                    <router-link :to="{ name: 'case-document-list' }">
                                        <span class="nav-text d-inline">Case Documents</span>
                                    </router-link>
                                </li>
                            </ul>
                            
                          </li>
                          <li v-if="userPermissionLabel('Auto Generate Session')">
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-file-text"></i></span>
                                <span class="nav-text">Auto Generate Session</span>
                            </a>
                            <ul class="nav-sub">
                                <li v-if="userPermissionSubLabel('Generate Session Notes')">
                                    <router-link :to="{ name: 'auto-generate-session' }">
                                        <span class="nav-text">Generate Session Notes</span>
                                    </router-link>
                                </li> 
                                <li v-if="userPermissionSubLabel('Generate Session Notes', 'Generate Session Report')">
                                    <router-link :to="{ name: 'sessions-queue' }">
                                        <span class="nav-text">Sessions Queue</span>
                                    </router-link>
                                </li> 
                                <li v-if="userPermissionSubLabel('Session Data Import')">
                                    <router-link :to="{ name: 'auto-generate-session-import' }">
                                        <span class="nav-text">Session Data Import <br>(Kareo File)</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Pending Target Data', 'List')">
                                    <router-link :to="{ name: 'pending-target-data' }">
                                        <span class="nav-text">Pending Target Data</span>
                                    </router-link>
                                </li> 
                                <!-- <li v-if="userPermissionSubLabel('Session Report', 'List')">
                                    <router-link :to="{ name: 'session-report' }">
                                        <span class="nav-text">Session Report</span>
                                    </router-link>
                                </li> -->
                                <li v-if="userPermissionSubLabel('Goal Import', 'List')">
                                    <router-link :to="{ name: 'mass-goal-import' }">
                                        <span class="nav-text">Goal Import</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Sheets Link', 'List')">
                                    <router-link :to="{ name: 'sheets-link' }">
                                        <span class="nav-text">Sheets Link</span>
                                    </router-link>
                                </li>
                            </ul>
                            <!-- v-if="hcodes_list" -->
                            <ul class="nav-sub">
                                <li v-if="userPermissionSubLabel('HTML Template')||userPermissionSubLabel('PDF Template')">
                                    <a>
                                        <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                        <span class="nav-text">Dynamic Session Template</span>
                                    </a>
                                    <ul class="nav-sub">
                                        <!--Depricated-->
                                        <!-- <li v-if="userPermissionSubLabel('HTML Template')">
                                            <router-link :to="{ name: 'dynamic-session-template' }">
                                                <span class="nav-text">HTML Template</span>
                                            </router-link>
                                        </li> -->
                                        <li v-if="userPermissionSubLabel('PDF Template', 'List')">
                                            <router-link :to="{ name: 'dynamic-pdf-session-template' }">
                                                <span class="nav-text">PDF Template</span>
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                          </li>
                          <li v-if="userPermissionLabel('Configuration')">
                              <a>
                                  <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                  <span class="nav-icon"><i class="fa fa-cog"></i></span>
                                  <span class="nav-text">Configuration</span>
                              </a>
                              <ul class="nav-sub">
                                  <!-- <li v-if="userPermissionSubLabel('Package')">
                                      <router-link :to="{ name: 'package' }">
                                          <span class="nav-text">Package</span>
                                      </router-link>
                                  </li> -->
                                  <li v-if="userPermissionSubLabel('Funders', 'List')">
                                      <router-link :to="{ name: 'payers' }">
                                          <span class="nav-text">Funders</span>
                                      </router-link>
                                  </li>
                                  <!--Depricated-->
                                  <!-- <li v-if="userPermissionSubLabel('Funders Plans')">
                                      <router-link :to="{ name: 'payer-plans' }">
                                          <span class="nav-text">Funders Plans</span>
                                      </router-link>
                                  </li> -->
                                  <li v-if="userPermissionSubLabel('Agency', 'List')">
                                      <router-link :to="{ name: 'agency' }">
                                          <span class="nav-text">Agency</span>
                                      </router-link>
                                  </li>
                                  <li v-if="userPermissionSubLabel('Certifications', 'List')">
                                      <router-link :to="{ name: 'certifications' }">
                                          <span class="nav-text">Certifications</span>
                                      </router-link>
                                  </li>
                                  <li v-if="userPermissionSubLabel('Locations', 'List')">
                                      <router-link :to="{ name: 'locations' }">
                                          <span class="nav-text">Locations</span>
                                      </router-link>
                                  </li>
                                  <li v-if="userPermissionSubLabel('Service Line', 'List')">
                                      <router-link :to="{ name: 'service-line' }">
                                          <span class="nav-text">Service Line</span>
                                      </router-link>
                                  </li>
                                  <li v-if="userPermissionSubLabel('Service', 'List')">
                                      <router-link :to="{ name: 'service' }">
                                          <span class="nav-text">Services</span>
                                      </router-link>
                                  </li>
                              <!-- <ul class="nav-sub" v-if="hcodes_list">
                                  <li>
                                      <router-link :to="{ name: 'billingcodes' }">
                                          <span class="nav-text">Billingcodes</span>
                                      </router-link>
                                  </li>
                              </ul> 
                              <ul class="nav-sub" v-if="diagnosis_list">
                                  <li>
                                      <router-link :to="{ name: 'diagnosis' }">
                                          <span class="nav-text">Diagnosis</span>
                                      </router-link>
                                  </li>
                              </ul> -->
                                  <li v-if="userPermissionSubLabel('Program Target Types', 'List')">
                                      <router-link :to="{ name: 'program-target-type-list' }">
                                          <span class="nav-text">Program Target Types</span>
                                      </router-link>
                                  </li>
                                  <!--Depricated-->
                                  <!-- <li v-if="userPermissionSubLabel('Target PlayList')">
                                      <router-link :to="{ name: 'goal' }">
                                          <span class="nav-text">Target PlayList </span>
                                      </router-link>
                                  </li> -->
                                  <li v-if="userPermissionSubLabel('Skill Area', 'List')">
                                      <router-link :to="{ name: 'skillareas' }">
                                          <span class="nav-text">Skill Area</span>
                                      </router-link>
                                  </li>
                                  <!--Depricated-->
                                  <!-- <li v-if="userPermissionSubLabel('BCBA Task')">
                                      <router-link :to="{ name: 'bcbatasks' }">
                                          <span class="nav-text">BCBA Task</span>
                                      </router-link>
                                  </li> -->

                              <!-- <ul class="nav-sub" v-if="hcodes_list">
                                  <li>
                                      <router-link :to="{ name: 'session-template' }">
                                          <span class="nav-text">Session Template</span>
                                      </router-link>
                                  </li>
                              </ul> -->

                              <!-- <ul class="nav-sub" v-if="hcodes_list">
                                  <li>
                                      <a>
                                        <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                        <span class="nav-text">Dynamic Session Template</span>
                                      </a>
                                      <ul class="nav-sub">
                                        <li>
                                            <router-link :to="{ name: 'dynamic-session-template' }">
                                                <span class="nav-text">HTML Template</span>
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{ name: 'dynamic-pdf-session-template' }">
                                                <span class="nav-text">PDF Template</span>
                                            </router-link>
                                        </li>
                                      </ul>
                                  </li>
                              </ul> -->

                              <!-- <ul class="nav-sub" v-if="program_list">
                                  <li>
                                      <router-link :to="{ name: 'programs' }">
                                          <span class="nav-text">Program Library</span>
                                      </router-link>
                                  </li>
                              </ul> -->
                              <!-- <ul class="nav-sub hide" v-if="assessments_category_list">
                                  <li>
                                      <router-link :to="{ name: 'assessment-category' }">
                                          <span class="nav-text">Assessment Category</span>
                                      </router-link>
                                  </li>
                              </ul> -->
                                <!--Depricated Start-->
                                  <!-- <li  v-if="userPermissionSubLabel('Stage Status Master')">
                                      <router-link :to="{ name: 'stage-status-master' }">
                                          <span class="nav-text">Stage Status Master</span>
                                      </router-link>
                                  </li>
                                  <li  v-if="userPermissionSubLabel('Stage Status')">
                                      <router-link :to="{ name: 'stage-status' }">
                                          <span class="nav-text">Stage Status</span>
                                      </router-link>
                                  </li>
                                  <li  v-if="userPermissionSubLabel('Behavior Category')">
                                      <router-link :to="{ name: 'behavior-category' }">
                                          <span class="nav-text">Behavior Category</span>
                                      </router-link>
                                  </li> -->
                                  <!--Depricated End-->
                                  <li  v-if="userPermissionSubLabel('Note Helper Settings', 'List')">
                                      <router-link :to="{ name: 'sessionform-category' }">
                                          <span class="nav-text">Note Helper Settings</span>
                                      </router-link>
                                  </li>
                                  <li  v-if="userPermissionSubLabel('Target Accuracy', 'List')">
                                      <router-link :to="{ name: 'target-accuracy' }">
                                          <span class="nav-text">Target Accuracy</span>
                                      </router-link>
                                  </li>
                                  <!--Depricated-->
                                  <!-- <li  v-if="userPermissionSubLabel('Target Promo Code')">
                                      <router-link :to="{ name: 'target-promo-code' }">
                                          <span class="nav-text">Target Promo Code</span>
                                      </router-link>
                                  </li> -->
                                  <li  v-if="userPermissionSubLabel('School', 'List')">
                                      <router-link :to="{ name: 'school' }">
                                          <span class="nav-text">School</span>
                                      </router-link>
                                  </li>
                                  <!--Depricated-->
                                <!-- <li  v-if="userPermissionSubLabel('Coverage Types')">
                                    <router-link :to="{ name: 'coverage-types' }">
                                        <span class="nav-text">Coverage Types</span>
                                    </router-link>
                                </li> -->
                                <li  v-if="userPermissionSubLabel('Reinforcement')">
                                    <router-link :to="{ name: 'reinforcement' }">
                                        <span class="nav-text">Reinforcement</span>
                                    </router-link>
                                </li>
                                <li  v-if="userPermissionSubLabel('Group Template Lesson')">
                                    <router-link :to="{ name: 'group-template-lession' }">
                                        <span class="nav-text">Group Template Lesson</span>
                                    </router-link>
                                </li>
                                <li  v-if="userPermissionSubLabel('Prompt Codes')">
                                    <router-link :to="{ name: 'prompt-code' }">
                                        <span class="nav-text">Prompt Codes</span>
                                    </router-link>
                                </li>
                                <li  v-if="userPermissionSubLabel('Groups')">
                                    <router-link :to="{ name: 'groups' }">
                                        <span class="nav-text">Groups</span>
                                    </router-link>
                                </li>
                                <li  v-if="userPermissionSubLabel('Classes', 'List')">
                                    <router-link :to="{ name: 'classes' }">
                                        <span class="nav-text">Classes</span>
                                    </router-link>
                                </li>
                              </ul>
                          </li>
                          <!-- <li>
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-align-left"></i></span>
                                <span class="nav-text">Dynamic Forms</span>
                            </a>
                            <ul class="nav-sub" v-if="dynamic_field_list">
                                <li>
                                    <router-link :to="{ name: 'dynamic-field' }">
                                        <span class="nav-text">Questions</span>
                                    </router-link>
                                </li>
                            </ul>
                            <ul class="nav-sub" v-if="assessments_list">
                                <li>
                                    <router-link :to="{ name: 'assessment' }">
                                        <span class="nav-text">Assessment Forms</span>
                                    </router-link>
                                </li>
                            </ul>
                            <ul class="nav-sub" v-if="checklist_master_list">
                                <li>
                                    <router-link :to="{ name: 'checklist-master' }">
                                        <span class="nav-text">Checklist Forms</span>
                                    </router-link>
                                </li>
                            </ul>
                            
                          </li> -->
                          <!-- <li v-if="file_list">
                              <router-link :to="{ name: 'files' }">
                                  <span class="nav-icon"><i class="fa fa-file"></i></span> <span class="nav-text">Files</span>
                              </router-link>
                          </li>
                          <li v-if="folder_list">
                              <router-link :to="{ name: 'folders' }">
                                  <span class="nav-icon"><i class="fa fa-folder"></i></span> <span class="nav-text">Folders</span>
                              </router-link>
                          </li> -->
                          
                          <li v-if="userPermissionLabel('File Management', 'List')">
                              <router-link :to="{ name: 'file-management' }">
                                  <span class="nav-icon"><i class="fa fa-folder"></i></span> <span class="nav-text">File Management</span>
                              </router-link>
                          </li>
                          <!--Depricated-->
                          <!-- <li v-if="userPermissionLabel('Intake Form')">
                              <router-link :to="{ name: 'intake-form' }">
                                  <span class="nav-icon"><i class="fa fa-file-text-o" aria-hidden="true"></i></span>
                                  <span class="nav-text">Intake Form</span>
                              </router-link>
                          </li> -->
                          <!-- <li>
                              <router-link :to="{ name: 'templates' }">
                                  <span class="nav-icon"><i class="fa fa-table" aria-hidden="true"></i></span>
                                  <span class="nav-text">Templates</span>
                              </router-link>
                          </li> -->
                          <!-- <li>
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-table" aria-hidden="true"></i></span>
                                <span class="nav-text">Templates</span>
                            </a>
                            <ul class="nav-sub">
                                <li>
                                    <router-link :to="{ name: 'templates' }">
                                        <span class="nav-text d-inline">Template List</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'merge-field' }">
                                        <span class="nav-text d-inline">Merge Field</span>
                                    </router-link>
                                </li>
                                 <li>
                                    <router-link :to="{ name: 'case-document-list' }">
                                        <span class="nav-text d-inline">Case Documents</span>
                                    </router-link>
                                </li>
                            </ul>
                            
                          </li> -->
                          <!--Depricated Start-->
                          <!-- <li v-if="userPermissionLabel('Message')">
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-envelope"></i></span>
                                <span class="nav-text">Message</span>
                            </a>
                            <ul class="nav-sub">
                                <li v-if="userPermissionSubLabel('Compose')">
                                    <router-link :to="{ name: 'compose' }">
                                        <span class="nav-text d-inline">Compose</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Inbox')">
                                    <router-link :to="{ name: 'inbox' }">
                                        <span class="nav-text d-inline">Inbox</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Sent')">
                                    <router-link :to="{ name: 'send' }">
                                        <span class="nav-text d-inline">Sent</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Trash')">
                                    <router-link :to="{ name: 'trash' }">
                                        <span class="nav-text d-inline">Trash</span>
                                    </router-link>
                                </li>
                            </ul>
                            
                          </li>
                          <li v-if="userPermissionLabel('Reminder')">
                            <router-link :to="{ name: 'reminders' }">
                                <span class="nav-icon"><i class="fa fa-bell"></i></span>
                                <span class="nav-text">Reminder</span>
                            </router-link>
                          </li> -->
                          <!--Depricated End-->
                          <li v-if="userPermissionLabel('Roles')">
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-user-circle-o"></i></span>
                                <span class="nav-text">Roles / Permissions</span>
                            </a>
                            <ul class="nav-sub">
                                <li v-if="userPermissionSubLabel('Role Group')">
                                    <router-link :to="{ name: 'roles' }">
                                        <span class="nav-text">Roles</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Add User Group')">
                                    <router-link :to="{ name: 'user-group' }">
                                        <span class="nav-text">Staff Title</span>
                                    </router-link>
                                </li>
                                <!--Depricated-->
                                <!-- <li v-if="userPermissionSubLabel('Permissions')">
                                    <router-link :to="{ name: 'permissions' }">
                                        <span class="nav-text">Permissions</span>
                                    </router-link>
                                </li> -->
                            </ul>
                          </li>
                          <li v-if="userPermissionLabel('Parents', 'List')">
                              <router-link :to="{ name: 'parents' }">
                                  <span class="nav-icon"><i class="fa fa-male"></i></span>
                                  <span class="nav-text">Parents</span>
                              </router-link>
                          </li>                          
                          <li v-if="userPermissionLabel('Graph')">
                              <a>
                                  <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                  <span class="nav-icon"><i class="fa fa-area-chart"></i></span>
                                  <span class="nav-text">Graph</span>
                              </a>
                              <ul class="nav-sub">
                                <!-- <li>
                                    <router-link :to="{ name: 'graph-abc-behavior' }">
                                        <span class="nav-text">Abc Graph</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'graph-student-behavior' }">
                                        <span class="nav-text">Students Behavior Graph</span>
                                    </router-link>
                                </li> -->
                                <li v-if="userPermissionSubLabel('Students Behavior Graph')">
                                    <router-link :to="{ name: 'graph-student-skill-behavior' }">
                                        <span class="nav-text">Students Behavior Graph</span>
                                    </router-link>
                                </li>
                                <li v-if="userPermissionSubLabel('Students Note Helper Graph')">
                                    <router-link :to="{ name: 'student-note-helper-graph' }">
                                        <span class="nav-text">Students Note Helper Graph</span>
                                    </router-link>
                                </li>
                                <!-- <li>
                                    <router-link :to="{ name: 'graph-data-import-list' }">
                                        <span class="nav-text">Graph Data Import List</span>
                                    </router-link>
                                </li> -->
                                <!-- <li>
                                    <router-link :to="{ name: 'graph-student-fluency-rate' }">
                                        <span class="nav-text">Fluency/rate Graph</span>
                                    </router-link>
                                </li> -->
                                <!-- <li>
                                    <router-link :to="{ name: 'daily-salary' }">
                                        <span class="nav-text">Daily Salary</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'due-payout' }">
                                        <span class="nav-text">Due Payout</span>
                                    </router-link>
                                </li> -->
                            </ul>
                          </li>
                          
                          <!-- <li>
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-align-left"></i></span>
                                <span class="nav-text">Session</span>
                            </a>
                            <ul class="nav-sub" v-if="session_template_type">
                                <li>
                                    <router-link :to="{ name: 'session-type' }">
                                        <span class="nav-text">Session Type</span>
                                    </router-link>
                                </li>
                            </ul>
                            <ul class="nav-sub" v-if="session_template_master">
                                <li>
                                    <router-link :to="{ name: 'session-master' }">
                                        <span class="nav-text">Session Templates</span>
                                    </router-link>
                                </li>
                            </ul>
                            <ul class="nav-sub" v-if="session_template_form">
                                <li>
                                    <router-link :to="{ name: 'session-form' }">
                                        <span class="nav-text">Session Form</span>
                                    </router-link>
                                </li>
                            </ul>
                            
                          </li> -->

                          

                          <!-- <li>
                            <router-link :to="{ name: 'intake-form' }">
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-file-text-o" aria-hidden="true"></i></span>
                                <span class="nav-text">Intake Form</span>
                            </router-link>
                            <ul class="nav-sub">
                                <li>
                                    <router-link :to="{ name: 'intake-form' }">
                                        <i class="fa fa-align-left d-inline p-2" aria-hidden="true"></i>
                                        <span class="nav-text d-inline">Intake Forms</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'add-intake-form' }">
                                        <i class="fa fa-plus d-inline p-2" aria-hidden="true"></i>
                                        <span class="nav-text d-inline">Add Intake Form</span>
                                    </router-link>
                                </li>
                            </ul>
                            
                          </li> -->
                          
                          <!-- <li>
                            <router-link :to="{ name: 'invoice' }">
                                <span class="nav-icon"><i class="fa fa-files-o"></i></span>
                                <span class="nav-text">Billing</span>
                            </router-link>
                          </li> -->
                          <li class="pb-2 hidden-folded"></li>
                      </ul>
                  </div>
              </div>
            </div>
            <!-- sidenav bottom -->
            <!-- <div class="no-shrink lt">
              <div class="nav-fold">
                  <a class="d-flex p-2-3" data-toggle="dropdown">
                      <span class="avatar w-28 grey hide">J</span>
                      <img src="@/assets/images/a0.jpg" alt="..." class="w-28 circle">
                  </a>
                  <div class="dropdown-menu  w pt-0 mt-2 animate fadeIn">
                      <a class="dropdown-item" @click="logOut">Sign out</a>
                  </div>
                  <div class="hidden-folded flex p-2-3 bg">
                      <div class="d-flex p-1">
                          <a href="#" class="flex text-nowrap">
                              <i class="fa fa-bell text-muted mr-1"></i>
                              <span class="badge badge-pill theme">20</span>
                          </a>
                          <a class="px-2" title="Logout" @click="logOut">
                              <i class="fa fa-power-off text-muted"></i>
                          </a>
                      </div>
                  </div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        components: {
        },
        props: {
            show_div_type: {
                type: Boolean,
                default: false,
            },
        },
        data () {
            return {
                user_type: '',
                isSuperAdmin: false,
                package_list:false,
                agency_list:false,
                school_list: false,
                service_list:false,
                service_line_list:false,
                payers_list:false,
                hcodes_list:false,
                goals_list:false,
                diagnosis_list:false,
                program_list:false,
                folder_list:false,
                file_management:true,
                assessments_category_list: false,
                file_list:false,
                assessments_list:false,
                certification_list:false,
                location_list: false,
                stage_status_master_list:false,
                checklist_master_list:false,
                dynamic_field_list:false,
                role_list:false,
                checklist_form_template_list:false,
                stage_status_list:false,
                payer_plan_list:false,
                session_template_type:false,
                session_template_master:false,
                session_template_form:false,
                behavior_category_list:false,
                office_staff_list:false,
                student_list:false,
                invoice_list:false,
                target_accuracy_list:false,
                target_promo_code:false,
                group_list: false,
                class_list: false
            }
        },
        created() {
        },

        computed: {

        },

        mounted() {
            this.checkUserType();
            this.stickySidebar();
        },


        methods: {
            ...mapActions(['logout']),
            logOut(){
                this.ishover = 0;
                this.ishover = 0;
                this.$store.commit('logout');
                this.$router.push({ name: 'home' });
            },
            userPermissionMethos(permission_method){
                if(!this.isSuperAdmin) {
                    let userPermission = JSON.parse(localStorage.getItem('userPermission'));
                    for (var i=0; i < userPermission.length; i++) {
                        if (userPermission[i].method == permission_method) {
                        return true;
                        }
                    }
                    return false;
                }

                return true;
            },
            userPermissionLabel(permission_label, view_list = null){
                if(!this.isSuperAdmin) {
                    let userPermission = JSON.parse(localStorage.getItem('userPermission'));
                    for (var i=0; i < userPermission.length; i++) {
                        if (userPermission[i].label == permission_label) {
                            if(view_list == null) {
                                return true;
                            } else if(userPermission[i].method == view_list) {
                                return true;
                            }
                        }
                    }
                    return false;
                }

                return true;
            },

            userPermissionSubLabel(permission_sub_label, view_list = null){
                if(!this.isSuperAdmin) {
                    let userPermission = JSON.parse(localStorage.getItem('userPermission'));
                    for (var i=0; i < userPermission.length; i++) {
                        if (userPermission[i].subLabel == permission_sub_label) {
                            if(view_list == null) {
                                return true;
                            } else if(userPermission[i].method == view_list) {
                                return true;
                            }
                        }
                    }
                    return false;
                }

                return true;
            },
            checkUserType() {
                let userType = JSON.parse(localStorage.getItem('userType'));
                this.user_type = userType;
                if (userType == 'Super Admin') {
                    this.isSuperAdmin = true;
                }
            },

            stickySidebar() {
                               
                // var $window = window);
                var lastScrollTop = document.documentElement.scrollTop;
                // var wasScrollingDown = true;

                var sidebar = document.getElementById("aside");
                if (sidebar) {

                    //  var initialSidebarTop = sidebar.offsetTop;
                    // console.log('sidebar', sidebar, initialSidebarTop)

                   window.addEventListener('scroll',(event) => {
                        // console.log('Scrolling...');
                        var windowHeight = window.innerHeight;
                        // var mainContentHeight = document.querySelector('#main-content').offsetHeight;
                        // var footerContentHeight = document.querySelector('#content-footer').offsetHeight;
                        var totalInnerHeight = windowHeight;
                        sidebar.setAttribute("style", "position: fixed; overflow-x:hidden; overflow-y:auto; height:" +totalInnerHeight+'px');
                    });
                }
                
            }

        },
    }
</script>

<style scoped>
::-webkit-scrollbar {
  width:5px;
}
/* ::-webkit-scrollbar-track {
  background: red; 
} */
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #00acc2; 
}
</style>
